import React, {useEffect} from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Img from "gatsby-image"

const ArticlePage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            markdown {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            mainPitch
            thumbnailImage {
              localFile {
                url
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const articles = data.allContentfulArticle.edges
  const currentArticle = articles && articles.filter(({ node: a }) => a.id === props.pageContext.article.id)[0].node
  currentArticle['type'] = 'article'

  const repsonsiveIframe = () => {
    // element that will be wrapped
    let iframe = document.querySelector('.iframe-div > iframe')
    // create wrapper container
    let wrapper = document.createElement('div');
    // insert wrapper before el in the DOM tree
    iframe.parentNode.insertBefore(wrapper, iframe);
    // move iframe into wrapper
    wrapper.appendChild(iframe);
  }
  const addClass = () => {
    repsonsiveIframe()
    let wrapperDiv = document.querySelector('.iframe-div > div')
    wrapperDiv.classList.add('responsive-youtube')
  }

  useEffect(() => {
    if (document.querySelector('.iframe-div > iframe')) {
      addClass()
      // console.log('Found iframe and wrapped it with the div')
    }
  }, [])

  return (
    <Layout >
      <SEO schemaData={currentArticle} title={currentArticle.title}/>
      <div className="px-4 section mb-4">
        <h1 className=" max-w-2xl mx-auto text-3xl text-brand-main font-semibold tracking-wide leading-snug mb-6 mt-4 lg:mb-10 lg:mt-16">{currentArticle.title}</h1>
        <h3 className=" max-w-2xl mx-auto text-3xl text-brand-main font-semibold tracking-wide leading-snug mb-6 mt-4 lg:mb-10 lg:mt-16 font-thin italic">{currentArticle.description}</h3>

        {currentArticle && currentArticle.thumbnailImage && currentArticle.thumbnailImage.localFile &&
          <Img fluid={currentArticle.thumbnailImage.localFile.childImageSharp.fluid} className="max-w-2xl mx-auto mb-16" />}
        <div className="mx-auto container text-brand-main">
          <div
            dangerouslySetInnerHTML={{ __html: currentArticle.markdown.childMarkdownRemark.html }}
            className="blog mx-auto max-w-2xl leading-relaxed tracking-wide iframe-div" />
        </div>
      </div>
      <div className={'mx-auto text-center mb-4 text-brand-main'}>
        <Link className="underline font-bold mx-1 mb-2 w-24 uppercase" to="/articles/page">Read more articles...</Link>
      </div>
    </Layout>
  )
}

export default ArticlePage